import * as React from 'react';
import { graphql } from 'gatsby';

import { ContactSection } from './components/contact-section';

import { PageHeading } from '~/components/page-heading';
import { getMappedHeading } from '~/core/data-mappers';
import { Seo } from 'gatsby-plugin-wpgraphql-seo';

const ContactTemplate = ({ data: { pageData } }) => {
  const pageHeading = getMappedHeading(pageData);

  return (
    <>
      <Seo post={pageData} />

      <PageHeading
        description={pageHeading.description}
        title={pageHeading.title}
        headingTag="h2"
      />

      <ContactSection />
    </>
  );
};

export const query = graphql`
  query($language: String, $slug: String, $locale: String) {
    pageData: wpPage(slug: { eq: $slug }, locale: { locale: { eq: $locale } }) {
      ...YoastSiteFragment
      ...PageHeadingFragment
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ...TranslationFragment
        }
      }
    }
  }
`;

export default ContactTemplate;
