import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { rem } from 'polished';
import styled from 'styled-components';

import { FadeIn } from '~/components/animations';
import { Heading } from '~/components/heading';
import { Paragraph } from '~/components/paragraph';
import { colors, Container, mediaQuery } from '~/theme';

export const HeadingWrapper = styled(Container)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: ${rem(64)} 0;

  ${mediaQuery('md')} {
    margin: ${rem(48)} 0;
  }

  ${mediaQuery('sm')} {
    margin: ${rem(32)} 0;
  }
`;

export const PageHeading = ({ headingTag, title, description }) => {
  return (
    <FadeIn threshold={0.3}>
      <Container>
        <HeadingWrapper center="xs">
          <Col xl={6} md={8} xs={12}>
            <Heading tag={headingTag} size={headingTag} color={colors.blue500}>
              {title}
            </Heading>
            <Paragraph size="lg">{description}</Paragraph>
          </Col>
        </HeadingWrapper>
      </Container>
    </FadeIn>
  );
};
