import React from 'react';
import { motion } from 'framer-motion';

import { useAnimationOnViewRef } from '~/core/hooks';

export const FadeIn = ({ children, threshold = 0.1, delay = 0, className }) => {
  const [animationRef, controls] = useAnimationOnViewRef(threshold, delay);

  return (
    <motion.div
      className={className}
      ref={animationRef}
      animate={controls}
      initial="hidden"
      transition={{ duration: 0.5 }}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 32 },
      }}
    >
      {children}
    </motion.div>
  );
};
