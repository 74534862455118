import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { rem } from 'polished';
import styled from 'styled-components';

import { FadeIn } from '~/components/animations';
import { Button } from '~/components/button';
import { Input } from '~/components/form';
import { Heading } from '~/components/heading';
import { Icon } from '~/components/icon';
import { IconBox } from '~/components/icon-box';
import { IconLink } from '~/components/icon-link';
import { REGEX } from '~/core/constants';
import { useGetForm } from '~/core/hooks';
import { Container } from '~/theme';
import { graphql, useStaticQuery } from 'gatsby';
import { getMappedOptions } from '~/core/data-mappers';

const ContactLinks = styled.div`
  margin: ${rem(32)} 0 ${rem(24)};

  > * {
    margin-bottom: ${rem(24)};
  }
`;

const ContactSocials = styled.div`
  display: flex;
  gap: ${rem(24)};
`;

const ContactForm = styled.form`
  margin-top: ${rem(32)};

  > * {
    margin-bottom: ${rem(32)};
  }
`;

export const ContactSection = () => {
  const {
    handleSubmit,
    onFormSubmit,
    formStatus,
    sharedFieldProps,
    translations,
  } = useGetForm();

  const { wp } = useStaticQuery(graphql`
    query contactQuery {
      wp {
        ...OptionsFragment
      }
    }
  `);

  const options = getMappedOptions(wp);

  return (
    <section>
      <Container>
        <Row>
          <Col xs={12} lg={5} lgOffset={1}>
            <FadeIn>
              <Icon name="send" size={32} />
              <Heading size="h4" tag="h2">
                {translations.letter}
              </Heading>

              <ContactForm onSubmit={handleSubmit(onFormSubmit)}>
                <Input
                  name="firstName"
                  iconName="person"
                  placeholder={translations.name}
                  {...sharedFieldProps}
                />
                <Input
                  iconName="envelope"
                  name="email"
                  type="email"
                  placeholder={translations.email}
                  pattern={REGEX.email}
                  {...sharedFieldProps}
                />

                <Input
                  iconName="chat"
                  name="message"
                  type="textarea"
                  placeholder={translations.messageContact}
                  {...sharedFieldProps}
                />

                <Button
                  size="xl"
                  variation="fill"
                  type="submit"
                  isDisabled={formStatus.isPending || formStatus.isSuccess}
                >
                  {formStatus.isPending
                    ? translations.pending
                    : formStatus.isSuccess
                    ? translations.successText
                    : translations.sendMessage}
                </Button>
              </ContactForm>
            </FadeIn>
          </Col>
          <Col xs={12} lg={5} lgOffset={1}>
            <FadeIn>
              <Icon name="info" size={32} />
              <Heading size="h4" tag="h2">
                {translations.info}
              </Heading>

              <ContactLinks>
                <IconLink
                  iconSize={24}
                  iconName="envelope"
                  title={options?.email}
                  href={`mailto:${options?.email}`}
                />
                <IconLink
                  iconSize={24}
                  iconName="phone"
                  title={`+${options?.phone}`}
                  href={`tel:+${options?.phone}`}
                />
                <IconLink
                  iconSize={24}
                  iconName="location"
                  title={`${options?.location}`}
                  href="https://goo.gl/maps/WaHaZDDvUyEuzEjT8"
                />
              </ContactLinks>

              <ContactSocials>
                <IconBox href={options?.facebook} iconName="facebook" />
              </ContactSocials>
            </FadeIn>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
